import React from 'react'

function App() {
  return (
    <div>
      Islam & MOurad 3azma
    </div>
  )
}

export default App
